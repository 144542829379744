import Cookies from "js-cookie";
import { log } from "./utils";

/**
 * Find the content entry in data layer and return the article's release date, if there is one.
 * Note, since content is pushed after page load this _might_ be a race condition. Use accordingly.
 * @returns {string} Publication date
 */
function getPageReleaseDate() {
  if (typeof window === undefined) {
    // TODO SSR
    return;
  }

  const dlEntry = window?.dataLayer.find((entry) => entry?.content?.publishedAtDate);
  if (dlEntry) {
    return dlEntry.content.publishedAtDate;
  }

  // @HACK use the raw data to avoid annoying race conditions.
  // Shouldn't matter for hopper since this is re-evaluated, but is a problem
  // @TODO: inline the data layers everywhere.
  // @ts-ignore
  if (window?.__DATA__) {
    // @ts-ignore
    return window?.__DATA__?.initialData?.dataLayerContent?.content?.publishedAtDate;
  }
}

/**
 * Determines if the user has the Inst. Access cookie based on their IP.
 *
 * @see https://sciam.atlassian.net/wiki/spaces/SA/pages/156172289/Institutional+Access
 * @returns {Boolean} - returns whether the user gets access to this page
 */
export function getInstitutionalAccess() {
  if (typeof window === "undefined") {
    return false;
  }

  const publishedDate = getPageReleaseDate();

  // The full details take two up to do API calls to validate
  // and one might have to filter on IP address. Assume it may
  // not be populated on the first render.
  let idpDetails = {};
  try {
    const idpDetailsCookieVal = Cookies.get("idp_details");
    if (idpDetailsCookieVal) {
      idpDetails = JSON.parse(idpDetailsCookieVal);
    }
  } catch (e) {
    console.error(e);
  }

  // Have full details: attempt date based access controls
  if (idpDetails?.BPID) {
    // For known IDP users when there's no known published date, do not paywall
    if (!publishedDate) {
      return true;
    }

    if (idpDetails.active_contracts === null) {
      // We have the BPID but no contracts, which tells me they
      // were a client but don't have any active entitlements.
      log("[entitlements] IDP accesss denied because there are no active entitlements");

      return false;
    } else if (idpDetails.active_contracts === undefined) {
      log(
        "[entitlements] IDP accesss granted because the entitlements are undefined, failing open",
      );
      // If the contracts property isn't provided, we're not filtering
      // on contracts yet/for this client.
      return true;
    }

    // Compare the date to the contract ranges
    const pubDate = new Date(publishedDate);
    let hasAccess = false;
    idpDetails.active_contracts.forEach((contract) => {
      let { to, from } = contract;
      const earliest = new Date(from);
      const latest = new Date(to);
      if (pubDate >= earliest && pubDate <= latest) {
        hasAccess = true;
      }
    });

    log(
      `[entitlements] IDP access ${
        hasAccess ? "GRANTED" : "DENIED"
      } for this page based on its release date`,
      {
        pubDate,
        active_contracts: idpDetails.active_contracts,
      },
    );
    return hasAccess;
  }

  /**
   * Some Magic Word institutions don't appear in the IP records, and because
   * of that we don't know to scan them for contracts. Fail open in this case.
   * Still required a BPID in the table.
   */
  if (Cookies.get("idp_magic") && idpDetails?.active_contracts === null) {
    return true;
  }

  return false;
}
