// @ts-check
import Cookies from "js-cookie";
import { createContext, useContext, useEffect, useState } from "react";

import { useAuth0Env } from "~/features/auth/hooks/use-auth0-env";
import { useEnvironment } from "~core/hooks/use-environment";
import { useAuth } from "~features/auth";
import { usePianoAuthSync, useSciAmJwtSync } from "~features/piano/hooks/use-piano-auth-sync";

import { getInstitutionalAccess } from "./institutional";
import { compareEntitlements, getPrimaryEntitlement, log } from "./utils";

const cachePrefix = "_sciam_entitlements";

// If the user has recently made a purchase, do not
// use frontend caching to avoid race conditions.
const cacheHasRecentEventKey = "_sa_recent_purchase_v2";

// And skip the Redis cache on the next API call
const cacheBustNextCallKey = "_sa_cachebest_entitlements_v2";

function getCacheKey(user_id) {
  return `${cachePrefix}_${user_id}`;
}

/**
 * Save entitlements for the user
 * @TODO - I can't use this until I figure out how to clear it
 *         when the user makes a purchase.
 */
function cacheEntitlements(user_id, value) {
  if (!user_id || !Array.isArray(value)) {
    return;
  }

  Cookies.set(getCacheKey(user_id), JSON.stringify(value), {
    expires: 1 / 24, // 1 hour
  });
  log(`[entitlements] entitlements cached`);
}

/**
 * Clear frontend entitlement cache.
 * This needs to run after a purchase or cancellation.
 */
export function clearEntitlementsCache() {
  const allCacheCookies = document.cookie
    .split(";")
    .filter((c) => c.trim().startsWith(cachePrefix))
    .map((c) => c.split("=")[0]);
  allCacheCookies.forEach((key) => Cookies.remove(key));
  Cookies.set(cacheHasRecentEventKey, "1", { expires: 1 / 24 }); // Do not use the frontend cache for 1 hour
  Cookies.set(cacheBustNextCallKey, "1", { expires: 1 });

  log(`[entitlements] cache cleared`);
}

/**
 *
 * @param {string} user_id
 * @return {string[] | null}
 */
function retrieveCachedEntitlements(user_id) {
  if (!user_id) {
    return null;
  }

  let hasRecentPurchase = !!Cookies.get(cacheHasRecentEventKey);
  if (hasRecentPurchase) {
    return null;
  }

  let value = Cookies.get(getCacheKey(user_id));
  if (!value) {
    return null;
  }
  try {
    value = JSON.parse(value);
  } catch (e) {
    log(`[entitlements] Invalid JSON in cache`, e);
    return null;
  }
  const isArrayOfStrings =
    Array.isArray(value) && value.filter((entry) => typeof entry !== "string").length === 0;
  if (!isArrayOfStrings) {
    log(`[entitlements] Unexpected data structure in cache`, value);
    return null;
  }
  log(`[entitlements] entitlements retrieved from cache`);
  // @ts-ignore
  return value;
}

/**
 * TODO: This might be better translated to TS
 *
 * @typedef {{
 *  hasAccess: boolean | undefined,
 * }} AccessState
 *
 * @typedef {{
 *   hasSub: boolean | undefined,
 *   hasAdFree: boolean | undefined,
 *   hasUnlimitedAccess: boolean | undefined,
 * }} FeatureAccessState
 *
 * @typedef {{
 *  name: string | undefined,
 *  id: string | undefined,
 * }} PrimaryEntitlementDetails
 *
 * @typedef {{
 *   entitlements: string[],
 *   primaryEntitlement: PrimaryEntitlementDetails | null,
 * }} EntitlementState
 *
 * @typedef {{
 *   hasInstitutionalAccess: boolean | undefined,
 * }} InstitutionalAccessState
 *
 * @typedef {{ jwt: boolean, api: boolean, inst: boolean } | false} AccessReady
 *
 * @typedef {{ ready: boolean }
 *  & AccessState
 *  & FeatureAccessState
 *  & EntitlementState
 *  & InstitutionalAccessState
 * } UserAccessContext
 */

const AccessContext = createContext(
  /** @type {UserAccessContext} */ ({
    ready: false,
    hasAccess: undefined,
    hasSub: undefined,
    hasAdFree: undefined,
    hasInstitutionalAccess: undefined,
    hasUnlimitedAccess: undefined,
    primaryEntitlement: undefined,
    entitlements: [],
  }),
);

function useProvideEntitlementsLayer() {
  const { isLoggedIn, isLoading, user } = useAuth();
  const { slug: auth0EnvSlug } = useAuth0Env();

  // Environment state
  const appEnv = useEnvironment();
  const isChargebee = appEnv.entitlements.provider === "chargebee";

  // Piano Auth Sync JWT (deprecated)
  const { currentToken } = usePianoAuthSync();

  // SciAm User JWT (includes entitlements from Chargebee)
  const jwt = useSciAmJwtSync();
  const features = isChargebee ? jwt?.payload?.features : undefined;

  // API Auth token
  const token = isChargebee ? jwt?.token : currentToken?.token;

  // Asynchronous state
  const [isReady, setIsReady] = useState(false);
  const [entitlements, setEntitlements] = useState([]);
  const { hasInstitutionalAccess } = useProvideInstitutionalAccess();

  // Computed Access State
  let hasAccess = features?.includes("digital-access");
  let hasSub = hasAccess;
  let hasAdFree = features?.includes("ad-free-access");
  let hasUnlimitedAccess = features?.includes("special-edition-access");
  let primaryEntitlement;

  if (isReady) {
    hasSub ||= compareEntitlements(entitlements, ["DIGITAL", "UNLMTD", "DIGPRINT"]);
    hasAccess ||= hasSub; // Based on sub assuming there's no institution
    hasAdFree ||= compareEntitlements(entitlements, ["UNLMTD"]);
    hasUnlimitedAccess ||= compareEntitlements(entitlements, ["UNLMTD"]);
    primaryEntitlement ||= getPrimaryEntitlement(entitlements);

    if (hasInstitutionalAccess) {
      hasAccess = true;
      hasUnlimitedAccess = true;
    }
  }

  function checkAccess() {
    const cachedValue = retrieveCachedEntitlements(user?.uid);
    if (cachedValue) {
      setEntitlements(cachedValue);
      setIsReady(true);
      return;
    }

    const SA_API_HOST = import.meta.env.PUBLIC_SA_API_HOST || "";
    let endpoint = new URL(
      `${SA_API_HOST}/platform/api/v2/accounts/access/`,
      `${window.location.protocol}//${window.location.hostname}`,
    );

    // Right after a purchase or account chance, ensure we bust the cache.
    if (Cookies.get(cacheBustNextCallKey)) {
      endpoint.searchParams.append("fresh", "1");
    }

    fetch(endpoint.toString(), {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "User-Authorization": `Bearer ${token}`,
        "x-auth-env": auth0EnvSlug,
      },
    })
      .then((data) => data.json())
      .then((resp) => {
        setEntitlements(resp?.entitlements || []);
        if (resp?.entitlements) {
          log("[entitlements] retrieved entitlements from API");
          cacheEntitlements(user?.uid, resp?.entitlements);
        }
        setIsReady(true);

        Cookies.remove(cacheBustNextCallKey); // Clear after 1 API call
      })
      .catch((e) => {
        log("[entitlements] error fetching entitlements", e);
        setIsReady(true);
      });
  }

  // User entitlements
  useEffect(() => {
    // This is already done
    if (isReady) {
      return;
    }

    if (!isLoggedIn && !isLoading) {
      log(`[entitlements] user is not signed in`);
      setEntitlements([]);
      setIsReady(true);
      return;
    }

    // JWT isn't ready yet
    if (!token || isChargebee) return;
    checkAccess();
  }, [isLoggedIn, isLoading, token]);

  if (isReady) {
    log(
      `[entitlements] user has the following subscription entitlements: ${entitlements.join(", ") || "none"}`,
    );
  }

  return {
    ready: isReady,
    hasAccess,
    hasSub,
    hasAdFree,
    hasInstitutionalAccess,
    hasUnlimitedAccess,
    primaryEntitlement,
    entitlements,
  };
}

function useProvideInstitutionalAccess() {
  const [hasInstitutionalAccess, setHasInstitutionalAccess] = useState(
    /** @type {boolean|undefined} */ (undefined),
  );

  useEffect(() => {
    // Institutional details will NOT update isReady.
    // User access can overlap with institutions in strange ways
    // so in effect institutional access becomes a fallback.
    function handleInstAccessChange() {
      let idpAccess = getInstitutionalAccess();
      if (idpAccess) {
        log(`[entitlements] user has access from their Institution`);
      }
      setHasInstitutionalAccess(idpAccess);
    }

    handleInstAccessChange();
    window.addEventListener("ipd-update", handleInstAccessChange);
    return () => window.removeEventListener("idp-update", handleInstAccessChange);
  }, []);

  return { hasInstitutionalAccess };
}

// Create the provider component
export function AccessProvider({ children }) {
  const value = useProvideEntitlementsLayer();
  // TODO: Refactor into a layer-based approach
  // // Features embedded in SciAm User JWT
  // const { ready: sciAmUserReady, ...featureAccess } = useSciAmUserAccessLayer();

  // // Entitlements from Django API
  // const { ready: entitlementsReady, ...entitlementsAccess } = useAsyncEntitlementsAccessLayer();

  // // Institutional access
  // const { ready: institutionalReady, ...institutionalAccess } = useInstitutionalAccessLayer();
  // const institutionalReady = typeof entitlementsAccess.hasInstitutionalAccess === "boolean";

  // const value = {
  //   // Report when each layer is ready
  //   ready:
  //     sciAmUserReady || entitlementsReady || institutionalReady
  //       ? {
  //           jwt: sciAmUserReady,
  //           api: entitlementsReady,
  //           inst: institutionalReady,
  //         }
  //       : false,

  //   // Merge all access layers
  //   ...union(entitlementsAccess, featureAccess, institutionalAccess),
  // };

  // return <AccessContext.Provider value={{ ready, ...access }}>{children}</AccessContext.Provider>;

  return <AccessContext.Provider value={value}>{children}</AccessContext.Provider>;
}

/**
 * @returns {UserAccessContext}
 */
export function useEntitlements() {
  return useContext(AccessContext);
}
